@media (width <= 72.5em) {
  .schedule-event h2 {
    font-size: 3.6rem;
  }

  .schedule-date {
    font-size: 2rem;
  }

  .schedule-location, .schedule-description {
    font-size: 1.6rem;
  }
}

@media (width <= 63em) {
  .story-timeline-container {
    grid-template-columns: 1fr;
    padding: 9.6rem;
  }

  .story-timeline-spacer {
    display: none;
  }

  .faqs-section-container {
    grid-template-rows: auto 1fr;
    grid-template-columns: none;
    row-gap: 8rem;
    padding: 9.6rem;
  }

  .faqs-section-title {
    text-align: center;
    grid-row: 1;
  }

  .faqs-section-subtitle {
    text-align: center;
  }

  .faq-items-container {
    grid-row: 2;
  }
}

@media (width <= 59em) {
  .header-container {
    padding: 0 2.4rem;
  }

  .no-scroll {
    overflow: hidden;
  }

  .main-nav-list {
    display: none;
  }

  .main-nav {
    justify-content: flex-end;
    display: flex;
  }

  .mobile-menu-btn {
    cursor: pointer;
    color: #ba945c;
    background-color: #ffffff57;
    border: none;
    border-radius: 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .8rem 1.6rem;
    transition: all .3s ease-out;
    display: inline-flex;
  }

  .mobile-menu-btn:hover, .mobile-menu-btn:active {
    background-color: #fffffffd;
  }

  .mobile-menu-btn--icon {
    color: #ba945c;
    padding: .3rem;
  }

  .mobile-menu {
    background-color: #f6f9fc;
    flex-direction: column;
    gap: 4.4rem;
    width: 100dvw;
    height: 100dvh;
    padding: 2.4rem;
    display: none;
    position: fixed;
  }

  .mobile-menu-header {
    justify-content: space-between;
    display: flex;
  }

  .mobile-menu-close-btn-container {
    cursor: pointer;
    background-color: #0000;
    border: 0 solid #0000;
    border-radius: 6px;
    width: 3.6rem;
    height: 3.6rem;
    display: inline-block;
  }

  .mobile-menu-close-btn {
    color: #ba945c;
    padding: .3rem;
  }

  .mobile-menu.active {
    display: flex;
  }

  .mobile-menu-nav-list {
    flex-direction: column;
    gap: 3.6rem;
    list-style: none;
    display: flex;
  }

  .mobile-menu-nav-item {
    font-size: 3.6rem;
  }

  .mobile-menu-nav-link:link, .mobile-menu-nav-link:visited {
    color: #425466;
    text-decoration: none;
    transition: all, all .125s ease-in-out;
  }

  .mobile-menu-nav-link:hover, .mobile-menu-nav-link:active {
    color: #ba945c;
  }

  .schedule-event h2 {
    font-size: 3rem;
  }

  .story-cover-container {
    padding: 6.4rem 4.8rem;
  }

  .section-travel-accommodations-container {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
  }

  .travel h2 {
    margin-bottom: 2.4rem;
    font-size: 4.4rem;
  }

  .travel p {
    font-size: 2.4rem;
  }

  .airplanes-container {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    scale: 150%;
  }

  .accommodations {
    padding: 30vh 4.8rem;
  }

  .accommodations h2 {
    margin-bottom: 2.4rem;
    font-size: 4.4rem;
  }

  .accommodations p {
    font-size: 2.4rem;
  }

  .btn-book:link, .btn-book:visited {
    padding: 1.6rem 2.4rem;
    font-size: 2.4rem;
  }

  .luna-img-box {
    width: 25%;
  }
}

@media (width <= 52em) {
  .schedule-timeline-img {
    width: 90%;
  }

  .story-cover-container {
    padding: 3.2rem 2.4rem;
  }

  .story-cover-heading {
    font-size: 3.2rem;
  }

  .story-cover-subheading {
    font-size: 1.6rem;
  }

  .registry-card-original:link, .registry-card-original:visited {
    padding: 3.2rem 0;
    font-size: 2rem;
  }

  .luna-img-box {
    width: 35%;
  }
}

@media (width <= 44em) {
  .schedule-event h2 {
    font-size: 2.4rem;
  }

  .schedule-date {
    font-size: 1.6rem;
  }

  .schedule-location, .schedule-description {
    font-size: 1.4rem;
  }

  .story-cover-container {
    padding: 2.4rem 2rem;
  }

  .story-cover-gallery {
    column-gap: 3rem;
  }

  .story-timeline-container {
    padding: 4.8rem;
  }

  .faqs-section-title {
    font-size: 6.4rem;
  }

  .faqs-section-container {
    padding: 6.4rem;
  }

  .registry-cover-title {
    font-size: 3rem;
  }

  .registry-card-original:link, .registry-card-original:visited {
    padding: 3.2rem 0;
    font-size: 2rem;
  }

  .section-vips {
    padding: 6.4rem;
  }

  .section-vips h2 {
    font-size: 6.4rem;
  }

  .footer-message {
    font-size: 2rem;
  }

  .footer-date {
    font-size: 5.4rem;
  }

  .countdown-timer {
    font-size: 2rem;
  }
}

@media (width <= 36em) {
  .mobile-menu-nav-item {
    font-size: 3rem;
  }

  .schedule-event h2 {
    letter-spacing: 0;
    font-size: 2rem;
  }

  .schedule-date, .schedule-location, .schedule-description {
    font-size: 1.2rem;
  }

  .story-cover-title {
    margin-bottom: 3rem;
    font-size: 3.6rem;
  }

  .story-cover-container {
    padding: 2rem 1.6rem;
  }

  .story-cover-gallery {
    column-gap: 2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .travel h2, .accommodations h2 {
    font-size: 3.6rem;
  }

  .faqs-section-container {
    row-gap: 6.4rem;
    padding: 4.8rem;
  }

  .faqs-section-title {
    font-size: 5.2rem;
  }

  .faqs-title-container {
    gap: 2.4rem;
  }

  .faq-item-title {
    font-size: 2rem;
  }

  .section-vips {
    row-gap: 6.4rem;
    padding: 4.8rem;
  }

  .section-vips h2 {
    font-size: 5.2rem;
  }

  .vip-card h3 {
    font-size: 2.4rem;
  }

  .vip-card p {
    font-size: 1.6rem;
  }
}

@media (width <= 31em) {
  .schedule-location, .schedule-description {
    font-size: 1.2rem;
  }

  .story-cover-title {
    margin-bottom: 2.4rem;
    font-size: 3rem;
  }

  .story-cover-container {
    padding: 1.6rem 0;
  }

  .story-cover-gallery {
    column-gap: 1.6rem;
  }

  .story-timeline-container {
    padding: 4.8rem 2.4rem;
  }

  .travel h2 {
    font-size: 3rem;
  }

  .travel p {
    font-size: 2rem;
  }

  .accommodations h2 {
    font-size: 3rem;
  }

  .accommodations p {
    font-size: 2rem;
  }

  .btn-book:link, .btn-book:visited {
    padding: 1.4rem 2rem;
    font-size: 2rem;
  }

  .faqs-section-container {
    row-gap: 4.8rem;
    padding: 6.4rem 2.4rem;
  }

  .faqs-section-title {
    font-size: 4.8rem;
  }

  .faq-item-title {
    font-size: 1.6rem;
  }

  .registry-cover-title {
    font-size: 2.4rem;
  }

  .registry-card-original:link, .registry-card-original:visited {
    font-size: 1.6rem;
  }

  .section-vips {
    row-gap: 4.8rem;
    padding: 6.4rem 2.4rem;
  }

  .section-vips-container {
    gap: 2.4rem;
  }

  .section-vips h2 {
    font-size: 4.8rem;
  }

  .luna-img-box {
    width: 40%;
  }
}

@media (width <= 27em) {
  .popup[data-active="true"] {
    width: 350px;
  }

  .schedule-event h2 {
    font-size: 1.6rem;
  }

  .schedule-date {
    font-size: 1.3rem;
  }

  .schedule-location, .schedule-description {
    padding: 0 1.4rem;
  }

  .schedule-timeline-img {
    width: 100%;
  }

  .story-cover-heading {
    font-size: 2.4rem;
  }

  .story-cover-subheading {
    font-size: 1.4rem;
  }

  .travel h2 {
    font-size: 2.4rem;
  }

  .travel p {
    font-size: 1.6rem;
  }

  .accommodations h2 {
    font-size: 2.4rem;
  }

  .accommodations p {
    font-size: 1.6rem;
  }

  .airplanes-container {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    scale: 125%;
  }

  .btn-book:link, .btn-book:visited {
    padding: 1.2rem 1.6rem;
    font-size: 1.6rem;
  }

  .section-vips-container {
    gap: 1.6rem;
  }

  .vip-card h3 {
    font-size: 2rem;
  }

  .vip-card p {
    font-size: 1.4rem;
  }

  .luna-img-box {
    width: 45%;
  }

  .footer-message {
    font-size: 1.6rem;
  }

  .footer-date {
    font-size: 4.4rem;
  }

  .countdown-timer {
    font-size: 1.6rem;
  }
}
/*# sourceMappingURL=index.7e5e7808.css.map */
